var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c(_vm.layout,{tag:"component"}),_c('CoolLightBox',{staticClass:"colllightbox",attrs:{"items":[
      {
        src: _vm.modalVideo.src,
        autoplay: false,
        muted: false,
        disablepictureinpicture: true,
      },
    ],"index":_vm.modalVideo.index},on:{"close":function($event){return _vm.onClose()},"on-change":function($event){return _vm.onOpen($event)},"on-open":function($event){return _vm.onOpen($event)}}}),_c('Alert')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }