import Vue from 'vue'
import VueRouter from 'vue-router'

import { Trans } from '@/plugins/translation'

Vue.use(VueRouter)

function load(pathToPageComponent) {
  return () => import(`@/views/${pathToPageComponent}`);
}


const routePath = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: "default",
    },
    beforeEnter: Trans.routeMiddleware,
    component: load("Home")
  },
]


const routes = [
  {
    path: "/",
    component: {
      template: "<router-view/>",
    },
    children: [...routePath].map(function (el) {
      return {
        ...el,
        beforeEnter: Trans.routeMiddleware,
        name: el.name,
      };
    }),
  },

  {
    path: `/:locale(${process.env.VUE_APP_I18N_SUPPORTED_LOCALE.replace(
      /,/g,
      "|"
    )})/`,
    component: {
      template: "<router-view/>",
    },
    children: [
      ...routePath
        .map(function (el) {
          return {
            ...el,
            beforeEnter: Trans.routeMiddleware,
            name: el.name + "Loc",
          };
        })
    ],
  },
]
let query = window.amw ? (window.amw.languageCode === window.amw.defaultLanguageCode) ? "/" : `/${window.amw.languageCode}/` : "/"
if (query !== "/") {
  Trans.changeLocale(window.amw.languageCode)
}
let router;
if (process.env.VUE_APP_BUILD_TYPE === "hybris") {
  router = new VueRouter({
    mode: 'history',
    base: `${query}onboarding/`,
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        if (to.hash) {
          return {
            selector: to.hash
            // , offset: { x: 0, y: 10 }
          }
        } else {
          return { x: 0, y: 0 }
        }
      }
    },
    routes,
  });
} else if (process.env.VUE_APP_BUILD_TYPE === "hybris-fqa") {
  router = new VueRouter({
    mode: 'history',
    base: `${query}onboarding/`,
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        if (to.hash) {
          return {
            selector: to.hash
            // , offset: { x: 0, y: 10 }
          }
        } else {
          return { x: 0, y: 0 }
        }
       
      }
    },
    routes,
  });
} else {
  router = new VueRouter({
    mode: 'history',
    base: "/",
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        if (to.hash) {
          return {
            selector: to.hash
            // , offset: { x: 0, y: 10 }
          }
        } else {
          return { x: 0, y: 0 }
        }
       
      }
    },
    routes,
  });
}

export default router
