export default {
    state: {
      survey: {},
    },
    getters: {
      getSurvey: (s) => s.survey,
    },
    mutations: {
      setSurvey(s, data) {
        s.survey = data;
      },
    },
    actions: {
      async getSurveyBySlug(ctx, options) {
        return this.$axios({
          method: 'GET',
          url: `v1/surveys/${options.slug}/?language=${options.lang}`,
          data: { slug: options.slug },
        })
          .then((result) => {
            // ctx.commit("setSurvey", result.data)
            return result.data
          })
          .catch((error) => {
            console.error(error)
            return false
          })
      },
      async sendSurvey(ctx, data) {
        return this.$axios({
          method: 'POST',
          url: `v1/survey_result/`,
          data: data,
        })
      }
     },
  };
  