import axios from "axios";
import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store'

axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_HOST}`
axios.defaults.headers.common.Authorization = localStorage.getItem('AuthorizeToken')
  ? `Bearer ${localStorage.AuthorizeToken}`
  : null
axios.defaults.validateStatus = (status) => {
  return status >= 200 && status <= 302
}




export default {
  async install() {
    Vue.prototype.$axios = axios
    Vuex.Store.prototype.$axios = axios
   
    await axios.interceptors.response.use(
      (response) => {
        store.commit('setShowContent', true)
        return response;       
      },
      async (error) => {
        const originalRequest = error.config;
        console.log(error);
        if (
          error &&
          error.response &&
          (error.response.status === 403 || error.response.status === 401)
        ) {
          if(process.env.VUE_APP_BUILD_TYPE === "hybris") {
            window.open('https://kz.amway.com', '_self')
          }
          return Promise.reject(error);
        } else if (error && error.response) {

            store.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
        }
      }
    );
  }
};
