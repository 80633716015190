<template>
  <div id="app">
    <component :is="layout"></component>
    <CoolLightBox
      class="colllightbox"
      :items="[
        {
          src: modalVideo.src,
          autoplay: false,
          muted: false,
          disablepictureinpicture: true,
        },
      ]"
      :index="modalVideo.index"
      @close="onClose()"
      @on-change="onOpen($event)"
      @on-open="onOpen($event)"
    >
    </CoolLightBox>
    <Alert/>
  </div>
</template>

<script>
const defaultLayout = "default";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { mapActions, mapGetters } from "vuex";

import Alert from "@/components/Alert.vue";

export default {
  name: "App",
  components: {
    CoolLightBox,
    Alert,
  },
  data: () => ({
    videoElement: null,
    videoCurrentTime: null,
    targetUID: null,
  }),
  computed: {
    ...mapGetters(["modalVideo"]),
    layout() {
      const layout = this.$route.meta.layout || defaultLayout;
      return () => import(`@/layouts/${layout}.vue`);
    },
  },
  methods: {
    ...mapActions(["closeVideoModal", "sendVideoView"]),
    async onOpen(e) {
      const vm = this;
      this.videoElement = document.querySelectorAll(".cool-lightbox-video")[e];
      if (this.videoElement) {
        this.videoElement.play();
        this.videoElement.disablePictureInPicture = true;
        this.videoElement.disableRemotePlayback = true;

        this.videoElement.addEventListener("timeupdate", (event) => {
          let progressLine = Math.round(
            (event.target.currentTime * 100) / (event.target.duration || 1)
          );
          if (progressLine >= 50 && !this.modalVideo.watched && this.modalVideo.src) {
            this.sendVideoView(this.modalVideo.slug);
            console.log("50% видео просмотрено");
          }
        });
      }
    },
    onClose() {
      this.closeVideoModal();
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
    },
    playVideo(src, uid, time, watched) {
      this.videoCurrentTime = time;
      this.video = src;
      this.index = 0;
      this.targetUID = uid;
      this.watched = watched;
    },
  },
};
</script>

<style lang="scss">
</style>
