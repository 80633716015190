import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "@/plugins/axios";
import { i18n } from './i18n'
import { Trans } from './plugins/translation'
import vSelect from 'vue-select';
import './assets/scss/styles.scss';
import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false
Vue.use(axios);
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.prototype.Trans = Trans


Vue.component("v-select", vSelect);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
