<template>
    <div
      class="alert"
      :style="`background-color: ${getAlertColor}`"
      v-if="getAlerts && Object.keys(getAlerts).length"
    >
      <div class="alert__text">{{ getAlerts.alerts }}</div>
      <div class="alert__close ml-4" @click="clearAlerts">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 3.5L12.5 12.5"
            stroke="currentColor"
            stroke-linecap="square"
          />
          <path
            d="M12.5 3.5L3.5 12.5"
            stroke="currentColor"
            stroke-linecap="square"
          />
        </svg>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "Alert",
    computed: {
      ...mapGetters(["getAlerts"]),
      getAlertColor() {
        switch (this.getAlerts.type) {
          case "error":
            return "#CB4050";
          case "notify":
            return "#C4D600";
          default:
            return "#000";
        }
      },
    },
    methods: {
      ...mapActions(["clearAlerts"]),
    },
    watch: {
      getAlerts() {
        if (this.getAlerts && Object.keys(this.getAlerts).length) {
          setTimeout(() => {
            this.clearAlerts();
          }, 3000);
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .alert {
    position: fixed;
    top: 60px;
    right: 30px;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 16px;
    border-radius: 8px;
    width: fit-content;
    color: #fff;
  
    @media screen and (max-width: 767px) {
      right: 50%;
      transform: translateX(50%);
      min-width: 90vw;
    }
  
    &__text {
      margin: auto 0;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  
    &__close {
      margin: auto 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  </style>