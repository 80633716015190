import Vue from 'vue'
import Vuex from 'vuex'
import survey from './modules/survey'
import test from './modules/test'
import video from './modules/video'
import audio from './modules/audio'
import step from './modules/step'
import alert from './modules/alert'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showContent: false
  },
  getters: {
    showContent: s => s.showContent
  },
  mutations: {
    setShowContent(s, state) {
      s.showContent = state
    }
  },
  modules: {
    survey,
    test,
    video,
    audio,
    step,
    alert,
  }
})
