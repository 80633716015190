export default {
    state: {
        audio: {},
    },
    getters: {
      getAudio: (s) => s.audio,
    },
    mutations: {
      setAudio(s, data) {
        s.audio = data;
      },
    },
    actions: {
      async getAudioBySlug(ctx, options) {
        return this.$axios({
          method: 'GET',
          url: `v1/audios/${options.slug}/?language=${options.lang}`,
          data: { slug: options.slug },
        })
          .then((result) => {
            // ctx.commit("setAudio", result.data)
            return result.data
          })
          .catch((error) => {
            console.error(error)
            return false
          })
      },
     },
  };
  