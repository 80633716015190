/* eslint-disable no-inner-declarations */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { i18n } from '@/i18n'

const Trans = {
  get defaultLocale() {
    return process.env.VUE_APP_I18N_LOCALE
  },

  get supportedLocales() {
    return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
  },

  get currentLocale() {
    return i18n.locale
  },

  set currentLocale(locale) {
    i18n.locale = locale
  },

  changeLocale(locale) {
    if (!Trans.isLocaleSupported(locale)) {
      return Promise.reject(false)
    }

    if (i18n.locale === locale) {
      return Promise.resolve(locale)
    }

    return Trans.loadLocaleFile(locale).then((msgs) => {
      i18n.setLocaleMessage(locale, msgs.default)
      return Trans.setI18nLocaleInServices(locale)
    })
  },

  isLocaleSupported(locale) {
    return this.supportedLocales.includes(locale)
  },

  loadLocaleFile(locale) {
    return import(`@/locales/${locale}.json`)
  },

  setI18nLocaleInServices(locale) {
    Trans.currentLocale = locale;
    document.querySelector("html").setAttribute("lang", locale);
    return locale;
  },


  async routeMiddleware(to, from, next) {
    // return next()
    console.log(to)
    const locale = to.params.locale
    if (to.params.locale === Trans.defaultLocale && to.name.includes('Loc')) {
      delete to.params.locale
      return next({ ...to, name: to.name.replace('Loc', '') })
    } else if (to.params.locale && Trans.isLocaleSupported(locale)) {
      return Trans.changeLocale(locale).then(() => next())
    } else if (to.params.locale && !Trans.isLocaleSupported(locale)) {
      delete to.params.locale
      return next({ ...to, name: to.name.replace('Loc', '') })
    } else {
      return next()
    }
    
  },

  

  i18nRoute(to) {
    return {
      ...to,
      params: { locale: this.currentLocale, ...to.params },
    }
  },
}

export { Trans }
